
    <template>
      <section class="content element-doc">
        <h2>Checkbox 多选框</h2>
<p>一组备选项中进行多选</p>
<h3>基础用法</h3>
<p>单独使用可以表示两种状态之间的切换，写在标签中的内容为 checkbox 按钮后的介绍。</p>
<demo-block>
        <div><p>在<code>el-checkbox</code>元素中定义<code>v-model</code>绑定变量，单一的<code>checkbox</code>中，默认绑定变量的值会是<code>Boolean</code>，选中为<code>true</code>。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;!-- `checked` 为 true 或 false --&gt;
&lt;el-checkbox v-model=&quot;checked&quot;&gt;备选项&lt;/el-checkbox&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const checked = ref(true)
      return { checked }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>禁用状态</h3>
<p>多选框不可用状态。</p>
<demo-block>
        <div><p>设置<code>disabled</code>属性即可。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-checkbox v-model=&quot;checked1&quot; disabled&gt;备选项1&lt;/el-checkbox&gt;
&lt;el-checkbox v-model=&quot;checked2&quot; disabled&gt;备选项&lt;/el-checkbox&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const checked = reactive({
        checked1: false,
        checked2: true
      })
      return { ...toRefs(checked) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>多选框组</h3>
<p>适用于多个勾选框绑定到同一个数组的情景，通过是否勾选来表示这一组选项中选中的项。</p>
<demo-block>
        <div><p><code>checkbox-group</code>元素能把多个 checkbox 管理为一组，只需要在 Group 中使用<code>v-model</code>绑定<code>Array</code>类型的变量即可。 <code>el-checkbox</code> 的 <code>label</code>属性是该 checkbox 对应的值，若该标签中无内容，则该属性也充当 checkbox 按钮后的介绍。<code>label</code>与数组中的元素值相对应，如果存在指定的值则为选中状态，否则为不选中。</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-checkbox-group v-model=&quot;checkList&quot;&gt;
  &lt;el-checkbox label=&quot;复选框 A&quot;&gt;&lt;/el-checkbox&gt;
  &lt;el-checkbox label=&quot;复选框 B&quot;&gt;&lt;/el-checkbox&gt;
  &lt;el-checkbox label=&quot;复选框 C&quot;&gt;&lt;/el-checkbox&gt;
  &lt;el-checkbox label=&quot;禁用&quot; disabled&gt;&lt;/el-checkbox&gt;
  &lt;el-checkbox label=&quot;选中且禁用&quot; disabled&gt;&lt;/el-checkbox&gt;
&lt;/el-checkbox-group&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const checkList = ref(['选中且禁用', '复选框 A'])
      return {
        checkList
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>indeterminate 状态</h3>
<p><code>indeterminate</code> 属性用以表示 checkbox 的不确定状态，一般用于实现全选的效果</p>
<demo-block>
        
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-checkbox
  :indeterminate=&quot;isIndeterminate&quot;
  v-model=&quot;checkAll&quot;
  @change=&quot;handleCheckAllChange&quot;
  &gt;全选&lt;/el-checkbox
&gt;
&lt;div style=&quot;margin: 15px 0&quot;&gt;&lt;/div&gt;
&lt;el-checkbox-group v-model=&quot;checkedCities&quot; @change=&quot;handleCheckedCitiesChange&quot;&gt;
  &lt;el-checkbox v-for=&quot;city in cities&quot; :label=&quot;city&quot; :key=&quot;city&quot;
    &gt;{{city}}&lt;/el-checkbox
  &gt;
&lt;/el-checkbox-group&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'

  const cityOptions = ['上海', '北京', '广州', '深圳']

  export default {
    setup() {
      const state = reactive({
        checkAll: false,
        checkedCities: ['上海', '北京'],
        cities: cityOptions, // modelValue 与 cities不能为同一个数组
        isIndeterminate: true
      })

      const handleCheckAllChange = (val) =&gt; {
        state.checkedCities = val ? [...cityOptions] : []
        state.isIndeterminate = false
      }
      const handleCheckedCitiesChange = (value) =&gt; {
        let checkedCount = value.length
        state.checkAll = checkedCount === state.cities.length
        state.isIndeterminate =
          checkedCount &gt; 0 &amp;&amp; checkedCount &lt; state.cities.length
      }
      return {
        ...toRefs(state),
        handleCheckAllChange,
        handleCheckedCitiesChange
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>可选项目数量的限制</h3>
<p>使用 <code>min</code> 和 <code>max</code> 属性能够限制可以被勾选的项目的数量。</p>
<demo-block>
        
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-checkbox-group v-model=&quot;checkedCities&quot; :min=&quot;1&quot; :max=&quot;2&quot;&gt;
  &lt;el-checkbox v-for=&quot;city in cities&quot; :label=&quot;city&quot; :key=&quot;city&quot;
    &gt;{{city}}&lt;/el-checkbox
  &gt;
&lt;/el-checkbox-group&gt;

&lt;script&gt;
  import { ref } from 'vue'

  const cityOptions = ['上海', '北京', '广州', '深圳']

  export default {
    setup() {
      const checkedCities = ref(['上海', '北京'])
      return {
        checkedCities,
        cities: cityOptions
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>按钮样式</h3>
<p>按钮样式的多选组合。</p>
<demo-block>
        <div><p>只需要把<code>el-checkbox</code>元素替换为<code>el-checkbox-button</code>元素即可。此外，Element 还提供了<code>size</code>属性。</p>
</div>
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-checkbox-group v-model=&quot;checkboxGroup1&quot;&gt;
    &lt;el-checkbox-button v-for=&quot;city in cities&quot; :label=&quot;city&quot; :key=&quot;city&quot;
      &gt;{{city}}&lt;/el-checkbox-button
    &gt;
  &lt;/el-checkbox-group&gt;
&lt;/div&gt;
&lt;div style=&quot;margin-top: 20px&quot;&gt;
  &lt;el-checkbox-group v-model=&quot;checkboxGroup2&quot; size=&quot;medium&quot;&gt;
    &lt;el-checkbox-button v-for=&quot;city in cities&quot; :label=&quot;city&quot; :key=&quot;city&quot;
      &gt;{{city}}&lt;/el-checkbox-button
    &gt;
  &lt;/el-checkbox-group&gt;
&lt;/div&gt;
&lt;div style=&quot;margin-top: 20px&quot;&gt;
  &lt;el-checkbox-group v-model=&quot;checkboxGroup3&quot; size=&quot;small&quot;&gt;
    &lt;el-checkbox-button
      v-for=&quot;city in cities&quot;
      :label=&quot;city&quot;
      :disabled=&quot;city === '北京'&quot;
      :key=&quot;city&quot;
      &gt;{{city}}&lt;/el-checkbox-button
    &gt;
  &lt;/el-checkbox-group&gt;
&lt;/div&gt;
&lt;div style=&quot;margin-top: 20px&quot;&gt;
  &lt;el-checkbox-group v-model=&quot;checkboxGroup4&quot; size=&quot;mini&quot; disabled&gt;
    &lt;el-checkbox-button v-for=&quot;city in cities&quot; :label=&quot;city&quot; :key=&quot;city&quot;
      &gt;{{city}}&lt;/el-checkbox-button
    &gt;
  &lt;/el-checkbox-group&gt;
&lt;/div&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'

  const cityOptions = ['上海', '北京', '广州', '深圳']

  export default {
    setup() {
      const checkboxGroups = reactive({
        checkboxGroup1: ['上海'],
        checkboxGroup2: ['上海'],
        checkboxGroup3: ['上海'],
        checkboxGroup4: ['上海']
      })
      return {
        ...toRefs(checkboxGroups),
        cities: cityOptions
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>带有边框</h3>
<demo-block>
        <div><p>设置<code>border</code>属性可以渲染为带有边框的多选框。</p>
</div>
        <template #source><element-demo6 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-checkbox v-model=&quot;checked1&quot; label=&quot;备选项1&quot; border&gt;&lt;/el-checkbox&gt;
  &lt;el-checkbox v-model=&quot;checked2&quot; label=&quot;备选项2&quot; border&gt;&lt;/el-checkbox&gt;
&lt;/div&gt;
&lt;div style=&quot;margin-top: 20px&quot;&gt;
  &lt;el-checkbox
    v-model=&quot;checked3&quot;
    label=&quot;备选项1&quot;
    border
    size=&quot;medium&quot;
  &gt;&lt;/el-checkbox&gt;
  &lt;el-checkbox
    v-model=&quot;checked4&quot;
    label=&quot;备选项2&quot;
    border
    size=&quot;medium&quot;
  &gt;&lt;/el-checkbox&gt;
&lt;/div&gt;
&lt;div style=&quot;margin-top: 20px&quot;&gt;
  &lt;el-checkbox-group v-model=&quot;checkboxGroup1&quot; size=&quot;small&quot;&gt;
    &lt;el-checkbox label=&quot;备选项1&quot; border&gt;&lt;/el-checkbox&gt;
    &lt;el-checkbox label=&quot;备选项2&quot; border disabled&gt;&lt;/el-checkbox&gt;
  &lt;/el-checkbox-group&gt;
&lt;/div&gt;
&lt;div style=&quot;margin-top: 20px&quot;&gt;
  &lt;el-checkbox-group v-model=&quot;checkboxGroup2&quot; size=&quot;mini&quot; disabled&gt;
    &lt;el-checkbox label=&quot;备选项1&quot; border&gt;&lt;/el-checkbox&gt;
    &lt;el-checkbox label=&quot;备选项2&quot; border&gt;&lt;/el-checkbox&gt;
  &lt;/el-checkbox-group&gt;
&lt;/div&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'

  export default {
    setup() {
      const state = reactive({
        checked1: true,
        checked2: false,
        checked3: false,
        checked4: true,
        checkboxGroup1: [],
        checkboxGroup2: []
      })
      return { ...toRefs(state) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Checkbox Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modelValue / v-model</td>
<td>绑定值</td>
<td>string / number / boolean</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>label</td>
<td>选中状态的值（只有在<code>checkbox-group</code>或者绑定对象类型为<code>array</code>时有效）</td>
<td>string / number / boolean</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>true-label</td>
<td>选中时的值</td>
<td>string / number</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>false-label</td>
<td>没有选中时的值</td>
<td>string / number</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>border</td>
<td>是否显示边框</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>size</td>
<td>Checkbox 的尺寸，仅在 border 为真时有效</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>name</td>
<td>原生 name 属性</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>checked</td>
<td>当前是否勾选</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>indeterminate</td>
<td>设置 indeterminate 状态，只负责样式控制</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
</tbody>
</table>
<h3>Checkbox Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>当绑定值变化时触发的事件</td>
<td>更新后的值</td>
</tr>
</tbody>
</table>
<h3>Checkbox-group Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modeValue / v-model</td>
<td>绑定值</td>
<td>array</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>size</td>
<td>多选框组尺寸，仅对按钮形式的 Checkbox 或带有边框的 Checkbox 有效</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>border</td>
<td>是否显示边框</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>min</td>
<td>可被勾选的 checkbox 的最小数量</td>
<td>number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>max</td>
<td>可被勾选的 checkbox 的最大数量</td>
<td>number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>text-color</td>
<td>按钮形式的 Checkbox 激活时的文本颜色</td>
<td>string</td>
<td>—</td>
<td>#ffffff</td>
</tr>
<tr>
<td>fill</td>
<td>按钮形式的 Checkbox 激活时的填充色和边框色</td>
<td>string</td>
<td>—</td>
<td>#409EFF</td>
</tr>
</tbody>
</table>
<h3>Checkbox-group Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>当绑定值变化时触发的事件</td>
<td>更新后的值</td>
</tr>
</tbody>
</table>
<h3>Checkbox-button Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>label</td>
<td>选中状态的值（只有在<code>checkbox-group</code>或者绑定对象类型为<code>array</code>时有效）</td>
<td>string / number / boolean</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>size</td>
<td>CheckboxButton 的尺寸</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>true-label</td>
<td>选中时的值</td>
<td>string / number</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>false-label</td>
<td>没有选中时的值</td>
<td>string / number</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>name</td>
<td>原生 name 属性</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>checked</td>
<td>当前是否勾选</td>
<td>boolean</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")

  return (_openBlock(), _createBlock(_component_el_checkbox, {
    modelValue: _ctx.checked,
    "onUpdate:modelValue": $event => (_ctx.checked = $event)
  }, {
    default: _withCtx(() => [
      _createTextVNode("备选项")
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const checked = ref(true)
      return { checked }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_checkbox, {
      modelValue: _ctx.checked1,
      "onUpdate:modelValue": $event => (_ctx.checked1 = $event),
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("备选项1")
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_checkbox, {
      modelValue: _ctx.checked2,
      "onUpdate:modelValue": $event => (_ctx.checked2 = $event),
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("备选项")
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue"])
  ], 64))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const checked = reactive({
        checked1: false,
        checked2: true
      })
      return { ...toRefs(checked) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")

  return (_openBlock(), _createBlock(_component_el_checkbox_group, {
    modelValue: _ctx.checkList,
    "onUpdate:modelValue": $event => (_ctx.checkList = $event)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_checkbox, { label: "复选框 A" }),
      _createVNode(_component_el_checkbox, { label: "复选框 B" }),
      _createVNode(_component_el_checkbox, { label: "复选框 C" }),
      _createVNode(_component_el_checkbox, {
        label: "禁用",
        disabled: ""
      }),
      _createVNode(_component_el_checkbox, {
        label: "选中且禁用",
        disabled: ""
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const checkList = ref(['选中且禁用', '复选框 A'])
      return {
        checkList
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString } = Vue

function render (_ctx, _cache) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_checkbox, {
      indeterminate: _ctx.isIndeterminate,
      modelValue: _ctx.checkAll,
      "onUpdate:modelValue": $event => (_ctx.checkAll = $event),
      onChange: _ctx.handleCheckAllChange
    }, {
      default: _withCtx(() => [
        _createTextVNode("全选")
      ]),
      _: 1
    }, 8, ["indeterminate", "modelValue", "onUpdate:modelValue", "onChange"]),
    _createVNode("div", { style: {"margin":"15px 0"} }),
    _createVNode(_component_el_checkbox_group, {
      modelValue: _ctx.checkedCities,
      "onUpdate:modelValue": $event => (_ctx.checkedCities = $event),
      onChange: _ctx.handleCheckedCitiesChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
          return (_openBlock(), _createBlock(_component_el_checkbox, {
            label: city,
            key: city
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(city), 1)
            ]),
            _: 2
          }, 1032, ["label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
  ], 64))
}
  
    
        const { reactive, toRefs } = Vue
        

  const cityOptions = ['上海', '北京', '广州', '深圳']

  const democomponentExport = {
    setup() {
      const state = reactive({
        checkAll: false,
        checkedCities: ['上海', '北京'],
        cities: cityOptions, // modelValue 与 cities不能为同一个数组
        isIndeterminate: true
      })

      const handleCheckAllChange = (val) => {
        state.checkedCities = val ? [...cityOptions] : []
        state.isIndeterminate = false
      }
      const handleCheckedCitiesChange = (value) => {
        let checkedCount = value.length
        state.checkAll = checkedCount === state.cities.length
        state.isIndeterminate =
          checkedCount > 0 && checkedCount < state.cities.length
      }
      return {
        ...toRefs(state),
        handleCheckAllChange,
        handleCheckedCitiesChange
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode } = Vue

function render (_ctx, _cache) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")

  return (_openBlock(), _createBlock(_component_el_checkbox_group, {
    modelValue: _ctx.checkedCities,
    "onUpdate:modelValue": $event => (_ctx.checkedCities = $event),
    min: 1,
    max: 2
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
        return (_openBlock(), _createBlock(_component_el_checkbox, {
          label: city,
          key: city
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(city), 1)
          ]),
          _: 2
        }, 1032, ["label"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        

  const cityOptions = ['上海', '北京', '广州', '深圳']

  const democomponentExport = {
    setup() {
      const checkedCities = ref(['上海', '北京'])
      return {
        checkedCities,
        cities: cityOptions
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode } = Vue

function render (_ctx, _cache) {
  const _component_el_checkbox_button = _resolveComponent("el-checkbox-button")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_el_checkbox_group, {
        modelValue: _ctx.checkboxGroup1,
        "onUpdate:modelValue": $event => (_ctx.checkboxGroup1 = $event)
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
            return (_openBlock(), _createBlock(_component_el_checkbox_button, {
              label: city,
              key: city
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(city), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { style: {"margin-top":"20px"} }, [
      _createVNode(_component_el_checkbox_group, {
        modelValue: _ctx.checkboxGroup2,
        "onUpdate:modelValue": $event => (_ctx.checkboxGroup2 = $event),
        size: "medium"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
            return (_openBlock(), _createBlock(_component_el_checkbox_button, {
              label: city,
              key: city
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(city), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { style: {"margin-top":"20px"} }, [
      _createVNode(_component_el_checkbox_group, {
        modelValue: _ctx.checkboxGroup3,
        "onUpdate:modelValue": $event => (_ctx.checkboxGroup3 = $event),
        size: "small"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
            return (_openBlock(), _createBlock(_component_el_checkbox_button, {
              label: city,
              disabled: city === '北京',
              key: city
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(city), 1)
              ]),
              _: 2
            }, 1032, ["label", "disabled"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { style: {"margin-top":"20px"} }, [
      _createVNode(_component_el_checkbox_group, {
        modelValue: _ctx.checkboxGroup4,
        "onUpdate:modelValue": $event => (_ctx.checkboxGroup4 = $event),
        size: "mini",
        disabled: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
            return (_openBlock(), _createBlock(_component_el_checkbox_button, {
              label: city,
              key: city
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(city), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ])
  ], 64))
}
  
    
        const { reactive, toRefs } = Vue
        

  const cityOptions = ['上海', '北京', '广州', '深圳']

  const democomponentExport = {
    setup() {
      const checkboxGroups = reactive({
        checkboxGroup1: ['上海'],
        checkboxGroup2: ['上海'],
        checkboxGroup3: ['上海'],
        checkboxGroup4: ['上海']
      })
      return {
        ...toRefs(checkboxGroups),
        cities: cityOptions
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo6": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_el_checkbox, {
        modelValue: _ctx.checked1,
        "onUpdate:modelValue": $event => (_ctx.checked1 = $event),
        label: "备选项1",
        border: ""
      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
      _createVNode(_component_el_checkbox, {
        modelValue: _ctx.checked2,
        "onUpdate:modelValue": $event => (_ctx.checked2 = $event),
        label: "备选项2",
        border: ""
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { style: {"margin-top":"20px"} }, [
      _createVNode(_component_el_checkbox, {
        modelValue: _ctx.checked3,
        "onUpdate:modelValue": $event => (_ctx.checked3 = $event),
        label: "备选项1",
        border: "",
        size: "medium"
      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
      _createVNode(_component_el_checkbox, {
        modelValue: _ctx.checked4,
        "onUpdate:modelValue": $event => (_ctx.checked4 = $event),
        label: "备选项2",
        border: "",
        size: "medium"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { style: {"margin-top":"20px"} }, [
      _createVNode(_component_el_checkbox_group, {
        modelValue: _ctx.checkboxGroup1,
        "onUpdate:modelValue": $event => (_ctx.checkboxGroup1 = $event),
        size: "small"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            label: "备选项1",
            border: ""
          }),
          _createVNode(_component_el_checkbox, {
            label: "备选项2",
            border: "",
            disabled: ""
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { style: {"margin-top":"20px"} }, [
      _createVNode(_component_el_checkbox_group, {
        modelValue: _ctx.checkboxGroup2,
        "onUpdate:modelValue": $event => (_ctx.checkboxGroup2 = $event),
        size: "mini",
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            label: "备选项1",
            border: ""
          }),
          _createVNode(_component_el_checkbox, {
            label: "备选项2",
            border: ""
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ])
  ], 64))
}
  
    
        const { reactive, toRefs } = Vue
        

  const democomponentExport = {
    setup() {
      const state = reactive({
        checked1: true,
        checked2: false,
        checked3: false,
        checked4: true,
        checkboxGroup1: [],
        checkboxGroup2: []
      })
      return { ...toRefs(state) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  